import React from "react"

import InternalLayout from "../layouts/internallayout";
import Seo from "../components/seo"
import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    max-width: 1150px;
    margin: 0 auto;
    padding: 4em 1em;
    position: relative;
    overflow: hidden;
`

const NotFoundPage = () => (
    <InternalLayout>
    <Seo title="404: Not found" />
        <Container>
            <h1>Page not found</h1>
            <p>Oops, it looks like you have clicked a broken link or entered an incorrect url.</p>
            <p>Please use the menu to find the pge you are after.</p>
        </Container>
    </InternalLayout>
)

export default NotFoundPage
